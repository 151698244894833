.side-bar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  border-right: 1px solid;
  border-radius: 0;
  border-color: rgba(64, 194, 133, 0.693);
  background-color: rgb(255, 255, 255);
  transition: 0.8s ease;
  z-index: 200;
  box-shadow: 0 6.4px 14.4px 0 rgb(0 0 0 / 13%),
    0 1.2px 3.6px 0 rgb(0 0 0 / 11%);
}

.toggle-menu {
  height: 50px;
  border-top-left-radius: 10rem;
  border-bottom-left-radius: 9rem;
  width: 10px;
  position: absolute;
  outline: none;
  z-index: 1;
  background-color: rgba(64, 194, 133, 0.693);
  border-color: rgba(64, 194, 133, 0.693);
  border-left: 0;
}
