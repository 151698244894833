article.camera-feed {
  position: relative;
  box-sizing: border-box;
}

article.camera-feed.overlay::before {
  position: absolute;
  content: "Loading Feed...";
  justify-content: center;
  align-items: center;
  top: 0;
  display: flex;
  width: 100%;
  min-height: 250px;
  content: "";
  z-index: 1;
  background-color: lightgray;
  border: 1px solid gray;
  opacity: 50%;
}

article.camera-feed.error::before {
  position: absolute;
  content: "No Feed Available";
  opacity: 80%;
  justify-content: center;
  align-items: center;
  top: 0;
  display: flex;
  width: 100%;
  height: 250px;
  content: "";
  z-index: 1;
  background-color: lightgray;
  border: 1px solid gray;
}

article.camera-feed > video {
  min-height: 250px;
  width: 100%;
}
