.person-activity {
  height: 100px;
  width: 100px;
}

.echarts-for-react div {
  font: inherit !important;
}

.echarts-for-react svg text {
  font-family: inherit !important;
  /**font-size: 12px !important;**/
}

.chart-tooltip.events-trend-tooltip time {
  font-weight: bold;
}

.chart-tooltip.events-trend-tooltip {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
