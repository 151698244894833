.user-dashboard {
  display: grid;
  height: 100vh;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto auto auto auto auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
}

.user-dashboard .health {
  grid-area: 2 / 1 / 3 / 3;
}
.user-dashboard .stats {
  grid-area: 1 / 1 / 2 / 7;
}
.user-dashboard .event-list {
  grid-area: 2 / 3 / 6 / 5;
  overflow: auto;
  height: 100%;
  padding-bottom: 10px;
}
.user-dashboard .whosein-list {
  grid-area: 2 / 5 / 6 / 7;
  overflow: auto;
}
.user-dashboard .most-active {
  grid-area: 3 / 1 / 4 / 3;
}
.user-dashboard .usage-chart {
  grid-area: 4 / 1 / 6 / 3;
}

/** Installer Dashboard **/
.installer-dashboard {
  display: grid;
  max-height: 100%;
  gap: 10px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 100px minmax(180px, auto) minmax(180px, auto);
}

.installer-dashboard > .event-list {
  overflow: auto;
  grid-area: 1 / 3 / 4 /3;
}

.installer-dashboard > .stats {
  overflow: auto;
  grid-area: 1 / 1 / 2 / 3;
}

.installer-dashboard > .health {
  overflow: auto;
  display: flex;
  flex-direction: column;
  grid-area: 2 / 1 / 2 / 2;
}

.installer-dashboard > .restore {
  overflow: auto;
  grid-area: 3 / 1 / 3 / 2;
  display: flex;
  flex-direction: column;
}

.installer-dashboard > .ac-issues {
  overflow: auto;
  grid-area: 3 / 2 / 4 / 3;
}

.installer-dashboard > .company-issues {
  overflow: auto;
  grid-area: 2 / 2 / 3 / 2;
}
