label[data-error]::before  {

    content: attr(data-error);
    display: block;
    position: absolute;
    bottom: -15px;
    font-size: 11px;
    left: 6px;
    color: #f44336;
  }
