.loading-bar-holder {
  width: 100%;
  height: 10px;
  border-radius: 3px;
  border: 1px solid #d2d2d2;
  background-color: #e6e6e6;
  max-width: 200px;
}
.loading-bar-holder .loading-bar {
  position: relative;
  width: 5%;
  height: 100%;
  background-color: #64bee6;
  animation: load 15s infinite;
}
.loading-bar-holder .loading-bar .loading-bar-shiny {
  background-color: white;
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  right: 0;
  opacity: 0.3;
  animation: shine 1.175s infinite;
}
@keyframes load {
  0% {
    width: 5%;
  }
  100% {
    width: 100%;
    background-color: #2fe74b;
  }
}
@keyframes shine {
  0% {
    width: 5%;
  }
  100% {
    width: 100%;
  }
}
