:root {
  --toastify-color-success: #008e5b;
  --toastify-color-error: #ea3737;
  --toastify-color-info: #267dff;
  --toastify-font-family: 'Inter';
  --toast-icon-fill-color-error: white;
  --toast-icon-fill-color-success: white;
}
:root.dark {
  --toastify-color-success: #1f6048;
  --toastify-color-error: #5b151b;
  --toastify-color-info: #212c42;
  --toastify-font-family: 'Inter';
  --toast-icon-fill-color-error: #fa3737;
  --toast-icon-fill-color-success: #05ae70;
}
.Toastify__toast.Toastify__toast--info {
  cursor: default;
}
div.Toastify__toast--error .Toastify__toast-body svg {
  fill: var(--toast-icon-fill-color-error);
}
div.Toastify__toast--success .Toastify__toast-icon svg {
  fill: var(--toast-icon-fill-color-success);
}
.Toastify__toast--info .Toastify__toast-icon {
}

.Toastify__toast-icon {
  margin-right: 16px;
  margin-left: 10px;
  width: auto;
}

.Toastify__toast-icon .spinner {
  --size: 24px;
}

.setup-toast {
  width: 25vw !important;
  background: white !important;
  color: black !important;
}

.Toastify__toast-body {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4;
}

.Toastify__toast-body small {
  display: block;
  font-size: 13px;
  font-weight: 400;
  max-width: 200px;
}

.Toastify__close-button {
  margin: auto;
  margin-right: 10px;
}

.custom-toast {
  background: gray;
  color: white;
}

.toast-default {
  background: var(--neutral-01);
  color: var(--neurtral--00);
}
