@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
:root {
  --light--primary--500: #008e5b;
  --light--primary--400-base: #00a96c;
  --light--primary--300: #b0e2d0;
  --light--primary--200: #e1fff4;
  --light--primary--100: #eefff9;
  --light--secundary--500: #3363ab;
  --light--secundary--400-base: #2480d6;
  --light--secundary--300: #d3e5ff;
  --light--secundary--200: #eef5ff;
  --light--secundary--100: #f5f9ff;
  --light--neutrals--900: #1d2023;
  --light--neutrals--800: #59606c;
  --light--neutrals--700: #7f8795;
  --light--neutrals--600: #b5bcc9;
  --light--neutrals--500: #c9cfda;
  --light--neutrals--400: #e3e6ec;
  --light--neutrals--300: #eceff3;
  --light--neutrals--200: #f3f5f9;
  --light--neutrals--100: #fafbfd;
  --light--neutrals--000: #ffffff;
  --light--neutrals--background: #fafbff;
  --light--others--red-500: #c60000;
  --light--others--red-400-base: #ea3737;
  --light--others--red-300: #ffcaca;
  --light--others--red-100: #fff4f6;
  --light--others--orange-500: #d76a05;
  --light--others--orange-400-base: #f5861f;
  --light--others--orange-300: #ffd8ac;
  --light--others--orange-200: #ffefde;
  --light--others--purple-500: #51358e;
  --light--others--purple-400-base: #6f49c0;
  --light--others--purple-300: #cabce5;
  --light--others--purple-200: #f0eafd;
  --dark--primary--500: #00e291;
  --dark--primary--400-base: #00c37d;
  --dark--primary--300: #004d31;
  --dark--primary--200: #003321;
  --dark--primary--100: #002417;
  --dark--secundary--500: #72bbff;
  --dark--secundary--400-base: #2d9aff;
  --dark--secundary--300: #135fa6;
  --dark--secundary--200: #002d56;
  --dark--secundary--100: #00172d;
  --dark--neutrals--900: #ffffff;
  --dark--neutrals--800: #aaabb4;
  --dark--neutrals--700: #8d8e97;
  --dark--neutrals--600: #67686d;
  --dark--neutrals--500: #54555b;
  --dark--neutrals--400: #43464e;
  --dark--neutrals--300: #3b3e44;
  --dark--neutrals--200: #28292c;
  --dark--neutrals--100: #1a1b1d;
  --dark--neutrals--000: #131414;
  --dark--neutrals--background: #000000;
  --dark--others--red-500: #ff7c7c;
  --dark--others--red-400-base: #ff3f3f;
  --dark--others--red-300: #9c0010;
  --dark--others--red-200: #3e0007;
  --dark--others--orange-500: #ffa654;
  --dark--others--orange-400-base: #ff8e25;
  --dark--others--orange-300: #582f00;
  --dark--others--orange-200: #311a00;
  --dark--others--purple-500: #af89ff;
  --dark--others--purple-400-base: #9563ff;
  --dark--others--purple-300: #361778;
  --dark--others--purple-200: #160046;
  /** Main Background color **/
  --neutral-00: #1d2023;

  /** Main Font color **/
  --neutral-01: #59606c;

  --neutral-02: #7f8795;
  --neutral-03: #b5bcc9;
  --neutral-04: #c9cfda;
  --neutral-05: #e3e6ec;
  --neutral-06: #eceff3;
  --neutral-07: #f3f5f9;
  --neutral-08: #fafbfd;
  --neutral-09: #ffffff;
  --neutral-10: #eceff3;
  --neutral-11: white;
  --neutral-12: #e3e6ec;
  --neutral-13: #eefff9;
  --neutral-14: #545b64;
  --neutral-15: #1d2023;
  --primary-base: #00a96c;
  --primary-v-01: #008e5b;
  --primary-v-02: #eefff9;
  --primary-v-03: #d8fff1;
  --primary-v-04: #b0e2d0;

  --secundary-base: #2480d6;
  --secundary-v-01: #3363ab;
  --secundary-v-02: #eaf2ff;
  --secundary-v-03: #d3e5ff;
  --red-base: #ea3737;
  --red-v-01: #c60000;
  --red-v-02: #fdebeb;
  --purple-base: #6428e4;
  --purple-v-01: #f0eafd;
  --orange-base: #f59728;
  --orange-v-01: #fff5ea;
  --form-column-width: 348px;
  --menu-item-color: #59606c;
  --menu-item-svg-size: 18px;
  --menu-item-height: 40px;
  --menu-item-font-size: 13px;
  --button-hover: #d3e5ff;
  --table-header-color: #59606c;
  --table-header-hover-color: black;
  --person-fill-color: #ccc;
  --person-fill-background-color: #a9a9a9;

  /** Badge variables badge.js **/

  --badge-background-green: #eefff9;
  --badge-background-blue: #eaf2ff;
  --badge-background-red: #fdebeb;
  --badge-background-orange: #fff5ea;
  --badge-background-purple: #f0eafd;
  --badge-background-grey: #f3f5f9;

  /** Stat cards variables headless-table-stats.js **/

  --stats-background-green: #eefff9;
  --stats-background-blue: #eaf2ff;
  --stats-background-red: #fdebeb;
  --stats-background-orange: #fff5ea;
  --stats-background-purple: #f0eafd;
  --stats-background-grey: #f3f5f9;

  /**nevigation arrows overview **/

  --hover-background-color: var(--secundary-v-03);

  --stopManaging-hover-background-color: #f2d6d6;

  --checkbox-border-color: #b9bbbf;
  --circleIcon-backgound-color: #f3f5f9;

  --logo-color: black;
  --allsiteselected-background-color: var(--secundary-v-03);

  --menu-hover-background-color: #f8f8f8;
  --motionStop-stats-background-color: #fafbfd;

  --camera-actionpanel-background: #1d2023;
  --camera-actionpanel-bottons-background: #b5bcc9;

  --secondary-font-color: #7f8795;
  --baselayer-botton-background-color: var(--neutral-07);

  --issues-badge-font-color: white;
  --door-icon-border-color: white;

  --event-icon-background-green: #eefff9;
  --event-icon-background-blue: #eaf2ff;
  --event-icon-background-red: #fdebeb;
  --event-icon-background-orange: #fff5ea;
  --event-icon-background-purple: #f0eafd;
  --event-icon-background-grey: #f3f5f9;

  /** Dashboard Var**/

  --dashboard-empty-holder-background-color: var(--primary-v-02);
  --dashboard-empty-holder-fill-color: white;

  /** Sidebar Var**/
  --sidebar-empty-holder-fill-color: white;

  --infinite-list-date-header-color: var(--neutral-08);

  --input-background: white;

  --door-avatar-locked-background: var(--red-base);
  --door-avatar-unlocked-background: var(--primary-base);
  --door-avatar-doublelocked-background: var(--orange-base);
  --door-avatar-accessed-background: var(--secundary-base);

  --door-avatar-locked-color: white;
  --door-avatar-unlocked-color: white;
  --door-avatar-doublelocked-color: white;
  --door-avatar-accessed-color: white;

  --door-avater-state-unknown: #7f8795;
  --door-avater-camera-stroke-color: white;
  --door-avater-camera-background: var(--red-base);

  --auxiliary-avatar-active-background: var(--orange-base);
  --auxiliary-avatar-idle-background: var(--secundary-base);

  --auxiliary-avatar-active-color: white;
  --auxiliary-avatar-idle-color: white;

  --marker-selector-background: #fafbfd;
  --marker-selector--hover-background-color: #;

  --chip-border-color: #d3e5ff;
  --bell-hover-color: #565;

  --clean-filter-color: #267dff;

  /** SingleValue Component in check box Background Color  **/

  --singleValue-background-color: #eefff9;

  /** MultiValue Component in check box Background Color  **/

  --multiValue-background-color: #eefff9;

  /** Event component background color  **/

  --event-online-background-color: #eefff9;
  --event-offlne-background-color: #fdebeb;
  --event-accessGranted-background-color: #eefff9;
  --event-accessDenied-background-color: #fdebeb;
  --event-openTooLong-background-color: #fdebeb;
  --event-forcedOpen-background-color: #fdebeb;
  --event-doorAccessed-background-color: #eefff9;
  --event-doorLocked-background-color: #fdebeb;
  --event-doorUnlocked-background-color: #eefff9;

  /** Site market in overview map **/

  --overview-site-marker-online-background-color: #eefff9;
  --overview-site-marker-offline-background-color: #fdebeb;

  /** Avatar circle Background color **/
  --avatar-circle-background-color: var(--purple-v-01);

  /** Label Background color **/
  --label-background-color: var(--purple-v-01);
  --label-color: var(--purple-base);

  /** Content Background Colo **/
  --content-background-color: var(--neutral-07);

  --date-picker-hover-color: #f0f0f0;
  --date-picker-header-color: #f0f0f0;

  --time-formater-background-color: #eaf2ff;
  --time-formater-color: #267dff;
  --popover-box-shadow: 0 8px 24px rgba(140, 149, 159, 0.2);
  --sync-check-report-modal-backgorund: #fafbfd;

  --table-filters-background: #fafbfd;

  --row-selected-color: #fafbfd;

  --skeleton-loading-background-color: white;

  --skeleton-loading-main-color: #fafbfd;
}
html.dark {
  --neutral-00: #b5bcc9;
  --neutral-01: #fafbfd;
  --neutral-02: #b5bcc9;
  --neutral-03: #eceff3;
  --neutral-04: #e3e6ec;
  --neutral-05: black;
  --neutral-06: #b5bcc9;
  --neutral-07: black;
  --neutral-08: #59606c;
  --neutral-09: #161b22;
  --neutral-10: #0d1117;
  --neutral-11: black;
  --neutral-12: #2c2f3a;
  --neutral-13: black;
  --neutral-14: white;
  --neutral-15: white;

  --red-v-02: #392022;
  --orange-v-01: #362e24;
  --secundary-v-02: #212c42;
  --secundary-v-03: #2c2f3a;
  --primary-v-02: #19372c;
  --primary-v-03: #19372c;
  --purple-v-01: #302b3c;
  --button-hover: black;

  --menu-item-color: #b5bcc9;
  --table-header-color: #b5bcc9;
  --table-header-hover-color: white;

  /** Badge variables badge.js **/

  --badge-background-green: black;
  --badge-background-blue: black;
  --badge-background-red: black;
  --badge-background-orange: black;
  --badge-background-purple: black;
  --badge-background-grey: black;

  /** Stat cards variables headless-table-stats.js **/

  --stats-background-green: black;
  --stats-background-blue: black;
  --stats-background-red: black;
  --stats-background-orange: black;
  --stats-background-purple: black;
  --stats-background-grey: black;

  /**nevigation arrows overview **/

  --hover-background-color: black;

  --stopManaging-hover-background-color: black;

  --checkbox-border-color: #505463;

  --circleIcon-backgound-color: black;

  --logo-color: white;

  --allsiteselected-background-color: #212c42;
  --menu-hover-background-color: black;
  --motionStop-stats-background-color: black;

  --camera-actionpanel-background: #1d2023;
  --camera-actionpanel-bottons-background: #b5bcc9;
  --baselayer-botton-background-color: var(--neutral-09);
  --secondary-font-color: var(--neutral-00);

  --issues-badge-font-color: black;
  --door-icon-border-color: black;

  --event-icon-background-green: #19372c;
  --event-icon-background-blue: #1d273a;
  --event-icon-background-red: #392022;
  --event-icon-background-orange: #362e24;
  --event-icon-background-purple: #302b3c;
  --event-icon-background-grey: black;

  /** Dashboard Var**/
  --dashboard-empty-holder-background-color: black;
  --dashboard-empty-holder-fill-color: black;

  /** Sidebar Var**/
  --sidebar-empty-holder-fill-color: black;

  /**infinite list date header**/
  --infinite-list-date-header-color: #2b2b2b;

  --input-background: black;

  --door-avatar-locked-background: black;
  --door-avatar-unlocked-background: black;
  --door-avatar-doublelocked-background: black;
  --door-avatar-accessed-background: black;

  --door-avatar-locked-color: var(--red-base);
  --door-avatar-unlocked-color: var(--primary-base);
  --door-avatar-doublelocked-color: var(--orange-base);
  --door-avatar-accessed-color: var(--secundary-base);

  --door-avater-state-unknown: black;
  --door-avater-camera-stroke-color: var(--red-base);
  --door-avater-camera-background: black;

  --auxiliary-avatar-active-background: black;
  --auxiliary-avatar-idle-background: black;

  --auxiliary-avatar-active-color: var(--orange-base);
  --auxiliary-avatar-idle-color: var(--secundary-base);

  --marker-selector-background: var(--neutral-09);
  --marker-selector--hover-background-color: black;

  --chip-border-color: var(--neutral-12);
  --bell-hover-color: black;

  /** SingleValue Component in check box Background Color  **/

  --singleValue-background-color: #19372c;

  /** MultiValue Component in check box Background Color  **/

  --multiValue-background-color: #19372c;

  /** Event component background color **/

  --event-online-background-color: #19372c;
  --event-offlne-background-color: #392022;
  --event-accessGranted-background-color: #19372c;
  --event-accessDenied-background-color: #392022;
  --event-openTooLong-background-color: #392022;
  --event-forcedOpen-background-color: #392022;
  --event-doorAccessed-background-color: #19372c;
  --event-doorLocked-background-color: #392022;
  --event-doorUnlocked-background-color: #19372c;

  /** Site market in overview map **/

  --overview-site-marker-online-background-color: #19372c;
  --overview-site-marker-offline-background-color: #392022;

  /** Avatar circle Background color **/
  --avatar-circle-background-color: var(--purple-v-01);

  /** Label Background color **/
  --label-background-color: black;
  --label-color: var(--purple-base);

  /** Content Background Colo **/
  --content-background-color: #0d1117;

  --date-picker-hover-color: black;
  --date-picker-header-color: #0d1117;

  --time-formater-background-color: black;
  --time-formater-color: var(--neutral-00);

  --popover-box-shadow: 0 8px 24px #010409;
  --sync-check-report-modal-backgorund: #0d1117;

  --table-filters-background: #1d2023;

  --row-selected-color: #1d2023;

  --skeleton-loading-background-color: #1d2023;

  --skeleton-loading-main-color: black;
}
body,
html {
  -webkit-font-smoothing: antialiased;
  user-select: none;
  font-family: 'Inter', sans-serif;
  overflow: hidden;
  color: var(--neutral-00);
}

h3,
h4,
h5 {
  margin: 0;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

body,
button,
input,
textarea,
select {
  font-family: 'Inter', sans-serif;

  -webkit-tap-highlight-color: transparent;
  outline: none;
}

button,
a,
select {
  font-family: 'Inter', sans-serif;

  cursor: pointer;
}

button {
  background-color: transparent;
  color: inherit;
  border-width: 0;
  padding: 0;
  cursor: pointer;
}
a,
a:hover {
  text-decoration: none;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

fieldset {
  border: none;
}

dd {
  margin: 0;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v55/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
    format('woff2');
}

.field {
  /* min-height: 100px; */
  position: relative;
  padding-bottom: 20px;
}

.field[data-compact] input {
  height: 40px;
}

label.table-search {
  width: 348px;
}

[data-icon-size-small]::before {
  font-size: 16px !important;
}
[data-icon-pre]::before,
[data-icon]::before,
[data-icon-pos]::after,
.material-icons {
  text-align: center;
  font-family: 'Material Icons';
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  font-size: 22px;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-feature-settings: 'liga';
  display: inline-block;
  line-height: 1;
  cursor: inherit;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  position: relative;
}

.brand[data-icon-pre]::before,
.brand[data-icon]::before,
.brand[data-icon-pos]::after {
  font-family: 'Brands';
}

[data-icon-pos]::after {
  content: attr(data-icon-pos);
}

[data-icon-pre]::before {
  content: attr(data-icon-pre);
  margin-right: 8px;
  vertical-align: middle;
}

[data-icon]::before {
  content: attr(data-icon);
}

/** scroll Bar **/
/**html {
    --scrollbarBG: #CFD8DC;
    --thumbBG: #90A4AE;
  }
::-webkit-scrollbar {
    width: 15px;
  }
::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
 ::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 12px;
    border: 2px solid var(--scrollbarBG);
  }

**/

::-ms-reveal {
  display: none;
}
/*Clear search from chrome **/
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
label.field,
div.field {
  /**max-width: 400px;**/
}

/** Charts **/
.c3-line {
  stroke-width: 3px;
}

/**leaflet**/
.leaflet-container {
  width: 100%;
  height: 100%;
  z-index: 1;
}

/** Dashed border animation **/

.border-animation {
  width: 100%;

  background: linear-gradient(90deg, rgb(217, 200, 217), transparent 50%),
    linear-gradient(90deg, rgb(217, 200, 217) 50%, transparent 50%),
    linear-gradient(0deg, rgb(217, 200, 217) 50%, transparent 50%),
    linear-gradient(0deg, rgb(217, 200, 217) 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 4px, 15px 4px, 4px 15px, 4px 15px;
  padding: 10px;
  animation: border-dance 60s infinite linear;
}

.border-animation::before {
  position: absolute;
  align-items: center;
  justify-content: center;
  content: '';
  background-color: rgb(226, 214, 214);
  opacity: 50%;
  z-index: 20;
  display: flex;
  height: 100%;
  width: 100%;
}

@keyframes border-dance {
  0% {
    background-position: 0 0, 100% 100%, 0 100%, 100% 0;
  }
  100% {
    background-position: 100% 0, 0 100%, 0 0, 100% 100%;
  }
}

::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  background: #eef;
}
::-webkit-scrollbar-thumb {
  background: #ccd;

  border-radius: 12px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/**checkboxes**/

input[type='checkbox'] {
  cursor: pointer;
  margin-right: 6px;
  appearance: none;
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

input[type='checkbox'].selection,
input[type='checkbox'].small {
  cursor: pointer;
  margin-right: 6px;
  appearance: none;
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

input[type='checkbox']:disabled {
  color: rgb(224, 224, 224) !important;
  cursor: default !important;
  background-color: rgb(224, 224, 224) !important;
}

input[type='checkbox']:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  color: #00a96c;
}

input[type='checkbox']:indeterminate {
  appearance: auto !important;
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  accent-color: #00a96c;
}

.Toastify__toast-container {
  min-width: var(--toastify-toast-width);
  width: auto;
}

.Toastify__toast-body {
  width: 100%;
}

.Toastify__toast {
  position: relative;
  min-height: 64px;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
  display: grid !important;
  grid-template-columns: 1fr fit-content(50px);
  justify-content: space-between;
  max-height: 800px;
  max-height: var(--toastify-toast-max-height);
  overflow: hidden;
  font-family: sans-serif;
  font-family: var(--toastify-font-family);
  align-items: center;
  width: auto;
  cursor: pointer;
  direction: ltr;
}

button[data-loading='true']::before,
dt[data-loading='true']::before {
  content: '';
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 3px solid #0004;
  border-color: #777;
  border-top-color: white;
  animation: button-spinner 0.7s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

@keyframes button-spinner {
  to {
    transform: rotate(360deg);
  }
}

@keyframes changeOpacity {
  0% {
    stroke: green;
    opacity: 1;
  }
  100% {
    stroke: gray;
    opacity: 0.5;
  }
}

div.react-datepicker__input-container > div > input {
  color: var(--neutral-00);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  width: 100%;
  height: 48px;
  background: var(--neutral-09);
  box-sizing: border-box;
  border-radius: 5px;
  outline: none;
  cursor: text;
  border: 1px solid var(--neutral-12);
}

div.react-datepicker__input-container > div > input[data-compact='true'] {
  height: 40px;
}
strong {
  font-weight: 600;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
svg.svg-spin {
  animation: spin 2s linear infinite;
}

thead:hover .table-resizer {
  visibility: visible;
}

.table-resizer {
  /* Displayed at the right side of column */
  position: absolute;
  visibility: hidden;
  top: 10;
  right: 0;
  width: 10px;
  cursor: col-resize;
  user-select: none;
  height: 50%;
  display: flex;
}

th[data-selection='true'] .table-resizer {
  visibility: hidden;
}

.select__menu-portal {
  z-index: 2000;
}

.circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #0000001a;
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

.save {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGNsYXNzPSJpY29uIGljb24tdGFibGVyIGljb24tdGFibGVyLXZpZXdwb3J0LXdpZGUiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlPSJjdXJyZW50Q29sb3IiIGZpbGw9Im5vbmUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PHBhdGggc3Ryb2tlPSJub25lIiBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIj48L3BhdGg+PHBhdGggZD0iTTEwIDEyaC03bDMgLTNtMCA2bC0zIC0zIj48L3BhdGg+PHBhdGggZD0iTTE0IDEyaDdsLTMgLTNtMCA2bDMgLTMiPjwvcGF0aD48cGF0aCBkPSJNMyA2di0zaDE4djMiPjwvcGF0aD48cGF0aCBkPSJNMyAxOHYzaDE4di0zIj48L3BhdGg+PC9zdmc+);
}

/** Leafelt **/
.leaflet-popup-content {
  margin: 0px !important;
}

.custom-div-icon {
  background-color: rgb(162, 163, 166);
  height: 32px !important;
  width: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 50%;
}

.custom-div-icon.unlock {
  background-color: var(--primary-base);
}

.custom-div-icon.unlock::after {
  border-color: var(--primary-base) transparent transparent transparent;
}

.custom-div-icon.lock {
  background-color: var(--red-base);
}

.custom-div-icon.lock::after {
  border-color: var(--red-base) transparent transparent transparent;
}

.custom-div-icon::after {
  content: '';
  position: absolute;
  top: 98%;
  left: 50%;
  margin-left: -4px;
  border-width: 5px;
  border-style: solid;
  border-color: rgb(162, 163, 166) transparent transparent transparent;
}

.leaflet-container {
  font: unset !important;
}

.leaflet-popup-content-wrapper {
  border-radius: 4px !important;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 2px solid rgba(200, 200, 200, 0.2) !important;
}

.pin.issue {
  background: var(--red-base);
}
.pin {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: var(--primary-base);
  position: absolute;
  /* -webkit-transform: rotate(-45deg);
  : rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg); */
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}
.pin:after {
  content: '';
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #2f2f2f;
  position: absolute;
  border-radius: 50%;
}

@keyframes bounce {
  0% {
    opacity: 0;

    transform: translateY(-2000px) rotate(-45deg);
  }
  60% {
    opacity: 1;

    filter: none;

    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    transform: translateY(0) rotate(-45deg);
  }
}

.leaflet-marker-icon.site-marker {
  width: 100% !important;
  height: 100% !important;
  background: transparent;
}

.fade-in {
  animation: fadeIn 1s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/** Clusters **/
.cluster {
  background: #2d84c8;
  border-radius: 50%;
  text-align: center;
  color: white;
  font-weight: 700;
  border: 1px solid #2d84c8;
  font-family: monospace;
}

.cluster:before {
  content: ' ';
  position: absolute;
  border-radius: 50%;
  z-index: -1;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  border: 1px solid white;
}

.digits-1 {
  font-size: 14px;
  height: 28px;
  width: 28px;
  line-height: 28px;
  margin-top: -14px;
  margin-left: -14px;
}

.digits-2 {
  font-size: 16px;
  height: 34px;
  width: 34px;
  line-height: 35px;
  margin-top: -17px;
  margin-left: -17px;
}

.digits-2:before {
  border-width: 2px;
}

.digits-3 {
  font-size: 18px;
  height: 48px;
  width: 47px;
  line-height: 47px;
  border-width: 3px;
  margin-top: -24px;
  margin-left: -24px;
}

.digits-3:before {
  border-width: 3px;
}

.digits-4 {
  font-size: 18px;
  height: 58px;
  width: 58px;
  line-height: 57px;
  border-width: 4px;
  margin-top: -29px;
  margin-left: -29px;
}

.digits-4:before {
  border-width: 4px;
}

/** PIN SQUARE **/
.pin-square {
  display: flex !important;
  align-items: center;
  justify-content: center;

  /* Set the marker size here */
  width: 2.25rem !important;
  height: 2.25rem !important;
  border-radius: 6px;
  /* Set the marker color here */

  /* optional fanciness */
  background-color: white;
  color: white;
}

.pin-square .indicator {
  position: absolute;
  right: -14px;
  top: -14px;
  width: 26px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pin-square .alarm {
  position: absolute;
  left: -14px;
  top: -14px;
  width: 26px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pin-square[data-alarm='forcedOpen'] .alarm,
.pin-square[data-alarm='openTooLong'] .alarm {
  background: var(--orange-base);
  padding: 0.1rem;
  border-radius: 50%;
}

.pin-square[data-state='Locked'] .indicator {
  background: #ea3737;
  padding: 0.1rem;
  border-radius: 4px;
}

.pin-square[data-state='Unlocked'] .indicator {
  background: #00a96c;
  border-radius: 4px;
  padding: 0.1rem;
}

.pin-square[data-state='Active'] .indicator {
  background: var(--orange-base);
  padding: 0.1rem;
  border-radius: 4px;
}

.pin-square[data-state='Idle'] .indicator {
  background: var(--secundary-base);
  border-radius: 4px;
  padding: 0.1rem;
}

.pin-square[data-online='false'] {
  background-color: var(--neutral-02);
  color: var(--neutral-02);
}

.pin-square[data-online='false'] > svg {
  color: white;
}

.pin-square:not(.no-shadow) {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

.pin-square.small {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.pin-square.small > svg {
  color: white;
  width: 16px;
  height: 16px;
}

.pin-square.no-shadow {
  position: relative;
}

.pin-square > svg {
  color: black;
  width: 16px;
  height: 16px;
}

.pin-square::after {
  content: ' ';
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: currentColor transparent transparent transparent;
}

.pin-square:not(.no-shadow)::before {
  content: '';
  background: rgba(147, 77, 77, 0.2);
  width: 75%;
  height: 75%;
  border-radius: 100%;
  position: absolute;
  top: 100%;
  left: 100%;
  transform: translate(-115%, -25%) rotate(90deg) scaleX(0.5);
}

/* .pin-square.offline {
  filter: grayscale(1);
}

.pin-square.online {
  background: var(--primary-base);
  color: var(--primary-base);
}

.pin-square.unlock {
  background: var(--primary-base);
  color: var(--primary-base);
}

.pin-square.lock {
  background: var(--red-base);
  color: var(--red-base);
} */

.custom-marker {
  background: transparent;
  width: auto !important;
  height: auto !important;
}

/** draggable-door **/

.draggable-door {
  background: var(--neutral-02);
  color: white;
  border-radius: 4px;
  padding: 0.4rem;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.edit-mode.custom-icon.leaflet-marker-icon:before {
  display: inline-block;
  width: 48px;
  height: 48px;
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-360' width='24' height='24' viewBox='0 0 24 24' stroke-width='2' stroke='currentColor' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M17 15.328c2.414 -.718 4 -1.94 4 -3.328c0 -2.21 -4.03 -4 -9 -4s-9 1.79 -9 4s4.03 4 9 4' /%3E%3Cpath d='M9 13l3 3l-3 3' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 2rem 2rem;
  position: absolute;
  top: -4px;
  left: -4px;
}

/* .leaflet-pm-draggable::before {
  display: inline-block;
  width: 24px;
  height: 24px;
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-drag-drop' width='24' height='24' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M19 11v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2' /%3E%3Cpath d='M13 13l9 3l-4 2l-2 4l-3 -9' /%3E%3Cpath d='M3 3l0 .01' /%3E%3Cpath d='M7 3l0 .01' /%3E%3Cpath d='M11 3l0 .01' /%3E%3Cpath d='M15 3l0 .01' /%3E%3Cpath d='M3 7l0 .01' /%3E%3Cpath d='M3 11l0 .01' /%3E%3Cpath d='M3 15l0 .01' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
  position: absolute;
  top: -4px;
  left: -4px;
} */

.tree-pattern {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-christmas-tree' width='24' height='24' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M12 3l4 4l-2 1l4 4l-3 1l4 4h-14l4 -4l-3 -1l4 -4l-2 -1z' /%3E%3Cpath d='M14 17v3a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-3' /%3E%3C/svg%3E");
}

/** Leaflet Tooltip **/
.leaflet-tooltip {
  --tooltip-background: #707070;
  background: var(--tooltip-background);
  text-shadow: 0 1px 1px #000;
  font-weight: normal;
  color: white;
  /**font-family: consolas, courier, monaco, menlo, monospace;**/
  border: none !important;
}

.leaflet-tooltip-right::before {
  border-right-color: var(--tooltip-background);
}

.leaflet-tooltip-left::before {
  border-left-color: var(--tooltip-background);
}

.leaflet-tooltip-bottom::before {
  border-bottom-color: var(--tooltip-background);
}

.leaflet-tooltip-top::before {
  border-top-color: var(--tooltip-background);
}

.leaflet-tooltip-right {
  margin-left: 40px;
  margin-top: 12px;
}

.leaflet-tooltip-left {
  margin-left: -16px;
  margin-top: 12px;
}

kbd {
  margin: 0px 0.1em;
  padding: 0.1em 0.6em;
  border-radius: 3px;
  border: 1px solid rgb(204, 204, 204);
  color: rgb(51, 51, 51);
  line-height: 1.4;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
  display: inline-block;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2), inset 0px 0px 0px 2px #ffffff;
  background-color: rgb(247, 247, 247);

  text-shadow: 0 1px 0 #fff;
}

.pm-textarea {
  background: white;
  color: #000;
  resize: none;
  border: none;
  outline: 0;
  cursor: pointer;
  border-radius: 3px;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}
.marker-icon {
  border-radius: unset !important;
}

:root {
  --rotate: 20deg;
}

.surveillance-container {
  width: 40px;
  height: 40px;
  position: relative;
  transform: rotate(var(--rotate));
}

.surveillance-cam {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #00a96c;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(calc(-1 * var(--rotate)));
}

.surveillance-area {
  width: 400%;
  height: 400%;
  position: absolute;
  background: linear-gradient(
    90deg,
    rgba(0, 169, 108, 1) 0%,
    rgba(0, 169, 108, 0) 100%
  );
  top: 50%;
  margin-top: -200%;
  left: 0;
  clip-path: polygon(15px 50%, 100% 0%, 100% 100%, 15px 50%);
}

.alert-movement {
  animation: alertMovement infinite 0.5s;
  transition: all linear 0.4s;
  background: linear-gradient(
    90deg,
    rgba(0, 255, 162, 1) 0%,
    rgba(0, 255, 162, 0) 100%
  );
  background-size: 100%;
}

@keyframes alertMovement {
  0% {
    background-position: 200%;
    background-size: 200%;
  }
  100% {
    background-position: 200%;
    background-size: 100%;
  }
}

a[data-disabled='true'] {
  pointer-events: none;
  cursor: default !important;
  color: unset;
}

/** rotate

0% {
    transform: rotate(0);
}
100% {
    transform: rotate(359deg);
}

//tw-rotate 2s ease infinite;
-**/

dl.info > dt {
  font-size: 12px;
  line-height: 100%;
  color: var(--neutral-01);
}
dl.info > dd {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--neutral-15);
  margin-bottom: 3rem;
}
